<template>
  <div>
    <b-container fluid class="mtop-200 privacy">
      <b-row class="font-sfns">
        <b-col>
         <h1 class="text-center">شروط وأحكام استخدام تطبيق مخطط </h1>
         <p class="mtop-50 text-right" dir="rtl">إن دخولك واستخدامك تطبيق مخطط يعتبر موافقة وإقرار على الأحكام والشروط الواردة في هذه الاتفاقية وموافقة وإقرار على استخدام التطبيق وفقاً لجميع الشروط والأحكام الواردة فيها، إذا كنت لا توافق على أي مما جاء فيها فيجب عليك عدم استخدام التطبيق.</p>

         <p class="mtop-50 text-right" dir="rtl">اتفاقية الاستخدام


        "مخطط" هو تطبيق سعودي عقاري يسعى لتوفير أسعار الصفقات العقارية والمعلومات العمرانية المتنوعة على الخريطة في مستويات المدن والمخططات وقطع الأراضي قدر الإمكان عن طريق جمع المعلومات من المصادر المعتمدة مما يتيح للمستخدم شفافية عالية في اتخاذ القرار.

        تم تطوير تطبيق مخطط بواسطة مؤسسة الخرائط البيانية، وهي مؤسسة سعودية مقرها جدة.

        "المستخدم" هو الفرد أو المؤسسة أو الشركة الذي يصل الى تطبيق ويستفيد من خدماته بشكل مباشر أو غير مباشر سواء كان ذلك بتسجيل أو من دون تسجيل.
         </p>
         <p class="mtop-50 text-right" dir="rtl">
         تخضع بنود وشروط وأحكام هذه الاتفاقية والمنازعات القانونية للقوانين والتشريعات والأنظمة المعمو
         بها في المملكة العربية السعودية.
         </p>
         <p class="mtop-50 text-right" dir="rtl">
           إن البيانات الموجودة في مخطط تم جمعها استناداً إلى البيانات الحكومية المفتوحة. 

يتم تقديم البيانات في مخطط على أساس "كما هي" و "كما هي متوفرة" دون أي ضمانات صريحة أو ضمنية، كما أن مخطط يخلي مسؤوليته من أي ضمانات صريحة أو ضمنية بما فيها على سبيل المثال لا الحصر، الضمانات الضمنية بصلاحية البيع أو الشراء في السوق والملائمة لغرض معين، كما لا يضمن مخطط أو يقدم أي مزاعم بخصوص استخدام هذه البيانات أو نتيجة لاستخدامها فيما يتعلق بصحتها أو دقتها أو موثوقيتها أو غير ذلك، ويتوجب على المستخدم تحمل جميع التكاليف اللازمة فيما يتعلق باستخدام أو الاعتماد على أي محتوى متاح على التطبيق، ولا يتحمل مخطط أي مسؤولية تجاه أي أضرار مباشرة أو غير مباشرة أو عرضية أو تالية أو عقابية تنشأ بأي طريقة كانت، نتيجة للدخول أو استخدام أي محتوى في هذا التطبيق أو المواقع المتصلة به، ويحتفظ مخطط بحق إدخال تغييرات في هذا التطبيق وفي المزاعم المضمنة كجزء من التطبيق في أي وقت يرغب فيه ودون إشعار مسبق. 

تخضع جميع البيانات الموجودة في مخطط، التابعة لأطراف خارجية للشروط والأحكام الخاصة بمزود البيانات الخارجي، ولا يتحكم مخطط ولا يعد مسؤول عن أي من البيانات الخاصة بالأطراف الخارجية أو
          </p>
          <p class="mtop-50 text-right" dir="rtl">
            عن أي محتوى تابع لطرف خارجي، وتقع المسؤولية على المستخدم مراجعة سياسات وشروط استخدام
            البيانات الخارجية المتاحة من قبل الأطراف الخارجيين.
            يجب على جميع المستخدمين التسجيل للوصول الى خدمات مخطط. 
            عند تسجيل المستخدم، فإنه يقر ويتعهد بالتالي: 
            <ol>
              <li>
                أن جميع المعلومات التي قدمها صحيحة ودقيقة وتحديثها بشكل دوري، ويحق لمخطط الغاء
                تسجيل المستخدم في حال اتضح عدم صحة المعلومات.
              </li>
              <li>
                    أن لم يسبق إيقاف حسابك في التطبيق أو منعك لاستخدام التطبيق في أي وقت من الأوقات. 
              </li>
              <li>
                    أنك لست منافساً لمخطط، كما أنك لا تقدم أي خدمات تنافس خدمات مخطط. 
              </li>
              <li>
                تقع مسؤولية حماية كلمة المرور التي تستخدم للوصول الى مخطط على المستخدم، ويجب
                عدم كشف كلمة المرور لأي طرف ثالث واخطار مخطط فور علم المستخدم بأي خرق للأمن أو
                استخدام غير مصرح به.
              </li>
              <li>
                عدم استخدام التطبيق بطريقة تتسبب أو يحتمل أن تتسبب في انتهاك الحقوق الخاصة بمخطط
                    و/أو أي طرف ثالث. 
              </li>
              <li>
                عدم إرسال أو نقل أو نشر أية معلومات تكون أو يحتمل أن تكون ضارة أو مخلة بالآداب أو
                افترائية أو غير قانونية أو تعطيل السرد الطبيعي للمحتويات المتضمنة على التطبيق أو من
                خلاله.
              </li>
              <li>
                عدم محاولة التدخل في عملية تشغيل التطبيق أو الوظائف الخاصة به، بما في ذلك، على سبيل
                المثال لا الحصر، تحميل أو تنزيل أو نقل الملفات التالفة أو فيروسات الحاسب أو المواد الأخرى
                الضارة أو استخدام أي برامج أو إجراءات أو أجهزة بهدف التدخل في التطبيق.
              </li>
              <li>
                عدم اتخاذ أي إجراء ينجم عنه تحميل مفرط أو غير معقول على البنية الأساسية للتطبيق أو
                المحاولة بإلحاق الضرر بالخدمة أو التطبيق بأي شكل من الأشكال.
              </li>
              <li>
                الحصول أو محاولة الحصول على الوصول غير المسموح للتطبيق، بما في ذلك الوصول من
                خلال كلمة مرور أو اسم مستخدم زائفين أو محاولة تحريف هويتك أو سلطة القيام بأي إجراء
                نيابة عن شخص آخر.
              </li>
              <li>
                    الالتزام بكافة القوانين واللوائح المعمول بها في المملكة العربية السعودية. 
              </li>
              <li>
                    متابعة أي إشعار يتم ارساله من خلال تطبيق مخطط. 
              </li>
              <li>
                استخدام التطبيق لأغراض مشروعة فقط، وعدم التسبب بالحاق أي ضرر أو إيذاء أو مضايقة أي
                طرق آخر.
              </li>
              <li>
                    عدم الاختراق أو التحايل على سياسة التطبيق أو أي حقوق تتعلق بطرف ثالث. 
              </li>
              <li>
                    عدم نسخ البيانات من تطبيق مخطط وإعادة نشره. 
              </li>
              <li>
                عدم استخدام أي وسيلة غير شرعية للوصول لبيانات المستخدمين الآخرين أو انتهاك لسياسة
                وحقوق مخطط أو الوصول لمحتوى التطبيق أو تجميع وتحصيل معلومات وبيانات تخص
                    المستخدمين الآخرين والاستفادة منها بأي شكل من الأشكال. 
              </li>
              <li>
                عدم انتهاك حقوق الطبع والنشر والعلامات التجارية، وبراءات الاختراع والدعاية وقواعد البيانات
                أو غيرها من حقوق الملكية أو الفكرية التي تنتمي لمخطط.
              </li>
              <li>
                        عدم انتهاك حقوق الآخرين الملكية أو الفكرية أو براءة الاختراع. 
              </li>
              <li>
                    عدم جمع محتويات مخطط لأغراض تجارية أو بيعها. 
              </li>
              <li>
                   عدم الإقدام على أي ما من شأنه إلحاق الضرر بسمعة مخطط. 
              </li>
              <li>
                عدم انتحال صفة مخطط أو ممثل لمخطط أو موظف في مخطط أو أي صفة توحي بأنك تابع
                لمخطط.
              </li>
              <li>
                عدم الحصول أو محاولة الحصول على دخول غير مصرح به الى التطبيق، أو انتحال أي شخصية
                أو تحريف العضوية مع شخص آخر.
              </li>
              <li>
                لا يتحمل مخطط أي مسؤولية عن أي خسارة في الأرباح أو خسائر اقتصادية من أي نوع نتيجة
                للمعلومات أو الخدمات التي تقدم في التطبيق.
              </li>
              <li>
                يحق لمخطط تغيير أو إلغاء أو تقييد الوصول إلى الخدمات أو جزء من الخدمات في أي وقت
                ولأي سبب بإشعار مسبق أو بدونه.
              </li>
              <li>
                    عدم استخدام مخطط لأغراض غير قانونية. 
              </li>
              <li>
                    عدم استخدام مخطط للقيام بأي انشطة تجارية دون موافقة مخطط. 
              </li>
              <li>
                عدم نسخ أي محتوى لإعادة نشره بشكل مطبوع او عبر الانترنت دون الحصول على موافقة
                مخطط.
              </li>
              <li>
                عدم استخدام المحتوى أو أي بيانات تصل إليها أو تحصل عليها من خلال مخطط في أي غرض
                غير الاستعمال الشخصي وسوف تستخدم التطبيق حصراً للأغراض الخاصة بك ولن تبيعها لأي
                طرف ثالث (بما في ذلك على سبيل المثال لا الحصر تقديم أي خدمة إلى أي شخص آخر).
              </li>
              <li>
                        عدم استخدام التطبيق للتسبب بإيذاء أو مضايقة أو إزعاج شخص آخر. 
              </li>
              <li>
                    لا يتحمل مخطط المسؤولية عن أي أضرار تنتج عن استخدام (أو عدم القدرة على استخدام)
                    التطبيق، بما في ذلك الأضرار التي تسببها البرامج الضارة أو الفيروسات، كما لا تتحمل مسؤولية
                    عدم صحة أو عدم اكتمال المعلومات أو التطبيق.
              </li>
            </ol>
          </p>
          <p class="mtop-50 text-right" dir="rtl">
           إذا تبين أن أي بند من هذه الاتفاقية غير قابل للتنفيذ، فستظل الأحكام المتبقية سارية المفعول والتنفيذ. 
           لا يعتبر عدم تفعيل أو تنفيذ أي بند من هذه الاتفاقية تنازلاً عن الحقوق ويحق لمخطط تفعيل وتنفيذ أي
           بند في أي وقت.
          </p>
          <h2 class="text-right mtop-50" dir="rtl">سياسة الخصوصية </h2>
          <p class="text-right" dir="rtl">
            تم إعداد هذه السياسة لمساعدتك في تفهم طبيعة البيانات التي يقوم مخطط بجمعها عند زيارتك
            التطبيق وكيفية التعامل مع هذه البيانات الشخصية.
            يتم تطبيق سياسة الخصوصية على كل المعلومات الشخصية التي يتم جمعها أو تقديمها لـ "مخطط"،
            ومن خلال تسجيلك وتقديم معلوماتك الشخصية فأنت توافق على سياسة الخصوصية.
          </p>
          <p class="mtop-50 text-right" dir="rtl">
            لا يقوم مخطط بجمع أي معلومات خاصة بك دون هدف محدد مسبقاً، وسيتم استخدام المعلومات
             الشخصية فقط بهدف تحقيق هذه الأسباب المحددة، أو لأي أغراض أخرى متوافقة، ولن يتم جمع أية معلومات أخرى دون أن الحصول على موافقتك، وسيتم الاحتفاظ بالمعلومات الشخصية طالما كان ذلك ضروريا لتحقيق هذه الأغراض.  

لن يتم جمع هذه المعلومات الشخصية الا عبر وسائل مشروعة فقط، وعند الحاجة فقط. 

المعلومات التي يتم جمعها هي معلومات شخصية تتضمن الاسم ورقم الهاتف المحمول والبريد الإلكتروني ومعلومات أخرى، منها معلومات عن تصفحك التطبيق. 

سيتم استخدام هذه المعلومات لمنحك تجربة خاصة وتقديم أفضل الخدمات وتطوير أداء التطبيق وتحسين الحدمة بتحليل البيانات في التطبيق وتقديم الاشعارات عبر الرسائل القصيرة والبريد الالكتروني وعمل احصائيات وتقارير ومشاركة المعلومات مع الجهات القانونية. 
          </p>
          <p class="mtop-50 text-right" dir="rtl">
            يحق لمخطط تعديل اتفاقية الاستخدام وسياسة الخصوصية دون اخطار مسبق، وتكون مسؤولية
             المستخدم مراجعة الاتفاقية والسياسة بشكل دوري لمعرفة التحديثات التي تطرأ على السياسة أو الاتفاقية.  
          </p>
        </b-col>
      </b-row>
    </b-container>
 </div>
</template>

<script>
export default {
  title: "الشروط والاحكام",
  name: 'Privacy',
  data() {
    return {
    }
  },
  components: {

  },
  computed: {
  },
  methods: {
  },
}
</script>
